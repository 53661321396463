/**
 * http://stackoverflow.com/a/15193615
 */
function Fraction() {

    this.toString = function () {
        var l = this.sign.toString().length;
        var sign = l === 2 ? '-' : '';
        var whole = this.whole !== 0 ? this.sign * this.whole + ' ' : sign;
        return whole + this.numerator + '/' + this.denominator;
    }

    this.convert = function (x, improper) {
        improper = improper || false;
        var abs = Math.abs(x);
        this.sign = x / abs;
        x = abs;
        var stack = 0;
        this.whole = !improper ? Math.floor(x) : 0;
        var fractional = !improper ? x - this.whole : abs;
        /*recursive function that transforms the fraction*/
        function recurs(x) {
            stack++;
            var intgr = Math.floor(x); //get the integer part of the number
            var dec = (x - intgr); //get the decimal part of the number
            if (dec < 0.0019 || stack > 20) return [intgr, 1]; //return the last integer you divided by
            var num = recurs(1 / dec); //call the function again with the inverted decimal part
            return [intgr * num[0] + num[1], num[0]]
        }

        var t = recurs(fractional);
        this.numerator = t[0];
        this.denominator = t[1];
    }
}

class TipGraph {

    constructor($target) {
        this.$target = $target;

        this.series = null;
        this.chart = null;
        this.ordinalState = true;
        this.pick = this.$target.data('pick');
        this.pickName = this.getPickName(this.pick);
        this.oddsUrl = this.$target.data('url');
        this.created = this.$target.data('created');
        this.minimalOdds = this.$target.data('minimal-odds');

        if (this.$target.length) {
            this.drawGraph();

            this.bindGraphSwitch();
        }
    }

    bindGraphSwitch() {
        let self = this
        $('.j-change-tip-graph-type').on('click', function () {

            if (self.ordinalState) {
                self.ordinalState = false;
            } else {
                self.ordinalState = true;
            }

            self.createChart();
            return false;
        });
    }

    drawGraph() {
        let self = this;

        $.getJSON(this.oddsUrl, function (data) {
            self.series = self.modifySeries(data.series);
            self.createChart();
        });
    }

    markVisibleSeries(series) {
        for (let i in series) {
            series[i].visible = this.pickName === series[i].name;
        }

        return series;
    }

    addMarkerToPoint(point) {
        point.marker = {
            enabled: true,
            height: 10,
            width: 10,
            radius: 10,
            lineColor: 'red',
            lineWidth: 2,
            fillColor: 'transparent'
        };

        return point;
    }

    convertPointArrayToObject(point) {
        return {
            x: point[0],
            y: point[1]
        }
    }

    addTipPostedPoint(series) {
        let createdDate = new Date(this.created);

        for (let i in series) {
            let serie = series[i];

            if (!serie.visible) {
                continue;
            }

            for (let j in serie.data) {
                let point = this.convertPointArrayToObject(serie.data[j]);
                let date = new Date(point.x);

                if (date.toISOString() === createdDate.toISOString()) {
                    point = this.addMarkerToPoint(point);
                    serie.data[j] = point;
                }
            }
        }

        return series;
    }

    removeOppositeSeries(series) {
        let singleSeries = [];

        for (let i in series) {
            if (this.pickName === series[i].name) {
                singleSeries.push(series[i]);
            }
        }

        return singleSeries;
    }

    getPickName(pick) {
        if (pick == 1) {
            return 'home';
        } else if (pick == 2) {
            return 'away'
        } else if (pick == 'x') {
            return 'draw';
        }
    }

    modifySeries(series) {
        series = this.markVisibleSeries(series);

        series = this.removeOppositeSeries(series);

        series = this.addTipPostedPoint(series);

        return series;
    }

    createChart() {
        this.chart = new Highcharts.StockChart({
            title: {
                text: null
            },
            rangeSelector: {
                selected: 0,
                buttons: [
                    {
                        type: 'all',
                        text: 'All'
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m'
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1w'
                    },
                    {
                        type: 'hour',
                        count: 24,
                        text: '24h'
                    },
                    {
                        type: 'hour',
                        count: 12,
                        text: '12h'
                    },
                    {
                        type: 'hour',
                        count: 6,
                        text: '6h'
                    },
                    {
                        type: 'hour',
                        count: 3,
                        text: '3h'
                    },
                    {
                        type: 'hour',
                        count: 1,
                        text: '1h'
                    }],
            },
            plotOptions: {
                line: {
                    marker: {
                        states: {hover: {enabled: false}}
                    }
                },
                series: {
                    step: true
                }
            },
            xAxis: {
                allowDecimals: false,
                minorTickLength: 0,
                tickLength: 0,
                maxZoom: 5,
                showLastLabel: true,
                endOnTick: true,
                min: 0,
                ordinal: this.ordinalState,
                plotLines: [{
                    color: '#000',
                    width: 2,
                    value: this.created,
                    label: {
                        text: 'Tip posted',
                        style: {
                            color: '#000'
                        },
                        rotation: 0
                    }
                }]
            },
            yAxis: [{
                title: {
                    text: null
                },
                plotLines: [{
                    value: 0,
                    width: 1
                }, {
                    color: '#ff0000',
                    width: 2,
                    value: this.minimalOdds,
                    label: {
                        text: 'Min. odds ' + this.minimalOdds,
                        style: {
                            color: '#ff0000'
                        },
                    },
                    zIndex: 100
                }]
            }, {
                linkedTo: 0,
                opposite: false
            }],
            legend: {
                enabled: false
            },
            tooltip: {
                xDateFormat: '%Y-%m-%d %H:%M',
                valueDecimals: 3,
                split: false,
                shared: true
            },
            series: this.series,
            chart: {
                renderTo: this.$target[0],
                backgroundColor: '#f0f0f2',
                type: 'line'
            },
            colors: ['#b3b3b3'],
            exporting: {
                enabled: false,
            }
        });
    }
}

$(function () {
    new TipGraph($('#tip-odds-graph'));
});
$(document).ready(function () {

    $('.j-collapsible-wysiwyg-text-toggle').on('click', function () {
        let indexNumber = $(this).data('index-number'),
            $target = $('#j-collapsible-wysiwyg-text-' + indexNumber);

        if ($target.is(':visible')) {
            $target.hide();
        } else {
            $target.show();
        }

        return false;
    });

});
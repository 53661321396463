$(function () {

    var $graph = $('#tipster-graph');

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    if ($graph.length > 0) {

        Vue.directive('chosen', {
            twoWay: true, // note the two-way binding
            bind: function () {
                $(this.el)
                    .chosen({
                        disable_search_threshold: 10
                    })
                    .change(function (ev) {
                        this.set(this.el.value);
                    }.bind(this));
            }
        });

        var url = $('.graph').data('url');

        var advancedStatsLabel = $('.j-showing-advanced-stats-label');

        new Vue({
            el: "#tipster-stats",
            data: {
                chartData: {},
                url: url,
                sport: "",
                // sport: getParameterByName('sport'),
                // tipsPage: getParameterByName('page'),
                // league: "",
                tip_origin: "",
                sport_options: [],
                showing_advanced_stats: false,
                showing_min_odds_graph: false,
                showing_closing_odds_graph: false,
                showing_profit_graph: true,
                showing_yield_graph: false,
                showing_profit_kelly_graph: false,
                showing_expected_profit_graph: false,
                showing_main_graph_profit: true,
                showing_main_graph_yield: false,
                current_graph_type: 'profit',
                is_pro: false,
                total: undefined,
                won: undefined,
                lost: undefined,
                avg_odds: undefined,
                p_value: undefined,
                avg_closing_odds: undefined,
                avg_minimal_odds: undefined,
                profit: undefined,
                closing_odds_profit: undefined,
                kelly_profit: undefined,
                kelly_yield: undefined,
                yield: undefined,
                closing_odds_yield: undefined,
                minimal_odds_yield: undefined,
                minimal_odds_profit: undefined,
                expected_profit: undefined,
                expected_yield: undefined,
                series: undefined,
                labels: undefined,
                turnover: undefined,
                yield_even: undefined,
                js_graph: undefined,
                yield_below: 0,
                yield_above: 0,
                yield_below_calculated: undefined,
                yield_above_calculated: undefined,
            },

            computed: {},

            watch: {
                'showing_advanced_stats': function () {
                    this.reload();
                    if (this.showing_advanced_stats) {
                        $('.j-showing-advanced-stats-label').html(advancedStatsLabel.data('title-alt'));
                        $('.j-showing_advanced_stats-toggle').addClass('active');
                    } else {
                        $('.j-showing-advanced-stats-label').html(advancedStatsLabel.data('title'))
                        $('.j-showing_advanced_stats-toggle').removeClass('active');
                    }
                },
                'showing_profit_graph': function () {
                    this.toggleSeries(this.js_graph.highcharts().series[0]);
                },
                'showing_min_odds_graph': function () {
                    this.toggleSeries(this.js_graph.highcharts().series[1]);
                },
                'showing_closing_odds_graph': function () {
                    this.toggleSeries(this.js_graph.highcharts().series[2]);
                },
                'showing_profit_kelly_graph': function () {
                    this.toggleSeries(this.js_graph.highcharts().series[3]);
                },
                'showing_expected_profit_graph': function () {
                    this.toggleSeries(this.js_graph.highcharts().series[4]);
                },
                'showing_yield_graph': function () {
                    if (this.showing_yield_graph) {
                        this.createYieldGraph();
                    } else {
                        this.toggleSeries(this.js_graph.highcharts().series[0]);
                    }
                    this.calculateYieldBelowAbove();
                },
                'sport': function () {
                    this.reload();
                },
                'current_graph_type': function () {
                    if (this.current_graph_type === 'profit') {
                        this.showing_main_graph_profit = true;
                        this.showing_main_graph_yield = false;
                        this.showing_yield_graph = false;

                        this.showing_profit_graph = true;
                        this.showing_closing_odds_graph = false;
                        this.showing_min_odds_graph = false;
                        this.showing_profit_kelly_graph = false;
                        this.showing_expected_profit_graph = false;

                        this.reload();
                    } else if (this.current_graph_type === 'yield_distribution') {
                        this.showing_main_graph_profit = false;
                        this.showing_main_graph_yield = true;
                        this.showing_yield_graph = true;
                    }
                },
                'tip_origin': function (val) {
                    this.reload();
                },
                'yield_below': function () {
                    this.calculateYieldBelowAbove();
                },
                'yield_above': function () {
                    this.calculateYieldBelowAbove();
                }
            },


            methods: {

                toggleSeries: function (series) {
                    if (!series) {
                        return;
                    }
                    if (series.visible) {
                        series.hide();
                    } else {
                        series.show();
                    }
                },

                reload: function () {
                    var self = this;
                    var fields = {
                        graph: this.graph,
                        sport: this.sport,
                        showing_advanced_stats: this.showing_advanced_stats,
                    };

                    $.getJSON(url, fields, function (data) {
                        var series = data.series;
                        var seriesClosing = data.series_closing;
                        var seriesMinimal = data.series_minimal;
                        var seriesKelly = data.series_kelly;
                        var seriesYieldDistribution = data.series_yield_distribution;
                        var seriesExpectedProfit = data.series_expected_profit;
                        var labels = data.labels;

                        var tipsView = data.tipsView;

                        self.setNewStats(data.stats);
                        self.sport_options = data.sport_options;

                        self.chartData = {
                            labels: labels,
                            series: series,
                            seriesClosing: seriesClosing,
                            seriesMinimal: seriesMinimal,
                            seriesKelly: seriesKelly,
                            seriesExpectedProfit: seriesExpectedProfit,
                            seriesYieldDistribution: seriesYieldDistribution,
                        };

                        self.createChart();

                        if (tipsView !== null) {
                            $('#tips-holder').html(tipsView);
                        }
                    });
                },

                createChart: function () {
                    var labels = this.chartData.labels;
                    var series = this.chartData.series;
                    var seriesClosing = this.chartData.seriesClosing;
                    var seriesMinimal = this.chartData.seriesMinimal;
                    var seriesKelly = this.chartData.seriesKelly;
                    var seriesYieldDistribution = this.chartData.seriesYieldDistribution;
                    var seriesExpectedProfit = this.chartData.seriesExpectedProfit;

                    allSeriesOptions = {
                        name: 'Profit',
                        data: series,
                    };

                    allSeriesOptions = Object.assign(allSeriesOptions, godsOfOddsGlobals.defaultGraphStyling)

                    var allSeries = [allSeriesOptions];

                    if (this.showing_advanced_stats) {
                        allSeries.push({
                            name: 'Profit min. odds',
                            color: "#ff1493",
                            data: seriesMinimal,
                            visible: false
                        });

                        allSeries.push({
                            name: 'Profit closing odds',
                            color: "#25fb25",
                            data: seriesClosing,
                            visible: false
                        });

                        allSeries.push({
                            name: 'Profit Kelly',
                            color: "#ffdf00",
                            data: seriesKelly,
                            visible: false
                        });

                        allSeries.push({
                            name: 'Closing line profit',
                            color: "#ff5500",
                            data: seriesExpectedProfit,
                            visible: false
                        });
                    }

                    // For some reason $graph variable dont work here
                    this.js_graph = $('#tipster-graph').highcharts({
                        chart: {
                            backgroundColor: null
                        },
                        title: {
                            text: null
                        },
                        xAxis: {
                            allowDecimals: false,
                            gridLineWidth: 1,
                            gridLineColor: 'rgba(255,255,255,0.4)',
                            lineColor: '',
                            minorTickLength: 0,
                            tickLength: 1,
                            maxZoom: 5,
                            showLastLabel: true,
                            endOnTick: true,
                            min: 0
                        },
                        yAxis: {
                            gridLineWidth: 1,
                            gridLineColor: 'lightgray',
                            lineColor: '',
                            title: {
                                text: null
                            },
                            plotLines: [{
                                value: 0,
                                width: 1
                            }]
                        },
                        tooltip: {
                            shared: true,
                            useHTML: true,
                            shadow: false,
                            crosshairs: {},
                            positioner: function () {
                                return {x: 47, y: 0};
                            },
                            formatter: function () {
                                tooltip = '';
                                if (this.points[0].point.date) {
                                    tooltip = this.points[0].point.date + '<br/>';
                                }
                                tooltip += this.points.reduce(function (s, point) {
                                    return s + '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> '
                                        + point.series.name + ': ' + point.y;
                                }, 'Number of tips: ' + this.x + '');
                                return tooltip;
                            },
                        },
                        series: allSeries,
                        plotOptions: {
                            series: {
                                turboThreshold: 0,
                                marker: {
                                    symbol: 'circle',
                                    radius: 2
                                },
                                states: {
                                    hover: {
                                        enabled: true,
                                        halo: {
                                            size: 0
                                        },
                                        lineWidthPlus: 0
                                    }
                                }
                            }
                        },
                        legend: {
                            enabled: false,
                        }
                    });

                    this.chartCreated();
                },

                chartCreated: function () {
                    if (this.showing_profit_graph) {
                        this.js_graph.highcharts().series[0].show();
                    } else {
                        this.js_graph.highcharts().series[0].hide();
                    }
                    if (this.showing_min_odds_graph) {
                        this.js_graph.highcharts().series[1].show();
                    }
                    if (this.showing_closing_odds_graph) {
                        this.js_graph.highcharts().series[2].show();
                    }
                    if (this.showing_profit_kelly_graph) {
                        this.js_graph.highcharts().series[3].show();
                    }
                    if (this.showing_expected_profit_graph) {
                        this.js_graph.highcharts().series[4].show();
                    }
                },

                createYieldGraph: function () {
                    $('#tipster-graph').highcharts({
                        title: {
                            text: null
                        },
                        series: [
                            {
                                name: 'Frequency',
                                data: this.chartData.seriesYieldDistribution,
                                zoneAxis: 'x',
                                zones: [
                                    {
                                        value: 0,
                                        color: '#ff6244'
                                    }
                                ],
                                color: "blue",
                                type: "area",
                                fillColor: {
                                    linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                                    stops: [
                                        [0, 'rgb(255, 98, 68, 1)'],
                                        [1, 'rgb(255, 98, 68, 0)']
                                    ]
                                },
                            }
                        ],
                        yAxis: {
                            title: {
                                text: 'Frequency'
                            },
                            labels: {
                                format: '{value}%'
                            },
                            max: 12,
                            min: 0
                        },
                        xAxis: {
                            title: {
                                text: 'Yield'
                            },
                            labels: {
                                format: '{value}%'
                            },
                            plotLines: [{
                                color: 'gray',
                                width: 2,
                                value: 0
                            }]
                        },
                        tooltip: {
                            positioner: function () {
                                return {x: 47, y: 0};
                            },
                            headerFormat: 'Yield: {point.key}%<br/>',
                            valueSuffix: '%',
                            crosshairs: {},
                        },
                        legend: {
                            enabled: false,
                        }
                    });
                },

                setNewStats: function (stats) {
                    var self = this;
                    for (var k in stats) {
                        if (stats.hasOwnProperty(k)) {
                            self[k] = stats[k];
                        }
                    }
                },

                calculateYieldBelowAbove: function () {

                    var
                        yield = parseFloat(this.yield) / 100,
                        impliedAvgFairOdds = this.avg_odds / (yield + 1),
                        impliedWinPercentage = (1 / impliedAvgFairOdds),
                        yieldStandardDeviation = (this.avg_odds * Math.pow((impliedWinPercentage - Math.pow(impliedWinPercentage, 2)), 0.5)) / Math.pow(this.total, 0.5) * 100,
                        profitablePercentage = 1 - normalDistribution(0, yield * 100, yieldStandardDeviation),
                        below = normalDistribution(this.yield_below, yield * 100, yieldStandardDeviation) * 100,
                        above = (1 - normalDistribution(this.yield_above, yield * 100, yieldStandardDeviation)) * 100;

                    this.yield_below_calculated = Math.round(below * 100) / 100 + '%';
                    this.yield_above_calculated = Math.round(above * 100) / 100 + '%';
                },

                init: function () {
                    // TODO: remove
                    this.tip_origin = 'fnon_competition';
                }
            },
            created: function () {
                this.init();
            }
        });
    }
});

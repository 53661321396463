(function ($) {

    $.fn.oddsConverter = function () {
        var calculate = {
            implied: function (decimal) {
                return Math.round(1 / decimal * 100 * 100) / 100 + '%'
            },
            impliedReverse: function (value) {
                return Math.round(100 / value * 100) / 100
            },
            american: function (decimal) {
                var americanOdds;
                if (decimal >= 2) {
                    americanOdds = (decimal - 1) * 100;
                } else {
                    americanOdds = (-100) / (decimal - 1);
                }

                americanOdds = Math.floor(americanOdds * 100) / 100;

                if (americanOdds > 0) {
                    americanOdds = '+' + americanOdds;
                }
                return americanOdds;
            },
            americanReverse: function (value) {
                if (value > 0) {
                    return (value / 100) + 1
                } else {
                    return (100 / value) + 1;
                }
            },
            malaysian: function (decimal) {
                var odds;
                if (decimal < 2) {
                    odds = decimal - 1;
                } else {
                    odds = -1 / (decimal - 1);
                }
                return format(odds);
            },
            malaysianReverse: function (value) {
                if (value > 0) {
                    return value + 1;
                } else {
                    return format(((100 / value / 100) - 1) * -1);
                }
            },
            fractional: function (decimal) {
                var frac = new Fraction()
                frac.convert(decimal - 1, true);

                return frac.toString();
            },
            fractionalReverse: function (value) {
                if (value.indexOf('/') === -1) {
                    return;
                }
                var bits = value.split('/');

                if (bits[1]) {
                    return bits[0] / bits[1] + 1;
                }
            },
            indonesian: function (decimal) {
                return format(calculate.american(decimal) / 100);
            },
            indonesianReverse: function (value) {
                return format(calculate.americanReverse(value) / 100);
            },
            hongKong: function (decimal) {
                return format(decimal - 1);
            },
            hongKongReverse: function (value) {
                return format(parseFloat(value) + 1);
            }
        };

        function format(value) {
            return Math.round(value * 100) / 100
        }

        return this.each(function () {
            var wrap = $(this),
                allInputs = $('.j-odds-input', wrap),
                $decimal = $('[data-odds-type="decimal"]', wrap),
                $fractional = $('[data-odds-type="fractional"]', wrap),
                $american = $('[data-odds-type="american"]', wrap),
                $implied = $('[data-odds-type="implied"]', wrap),
                $hongKong = $('[data-odds-type="hong-kong"]', wrap),
                $indonesian = $('[data-odds-type="indonesian"]', wrap),
                $malaysian = $('[data-odds-type="malaysian"]', wrap);

            allInputs.on('keydown, keyup', function (e) {
                if (!$(this).val()) {
                    allInputs.val('');
                    return;
                }

                $(this).commaToDot(e);

                var oddsType = $(this).data('odds-type'),
                    value = $(this).val(),
                    decimal = $decimal.val();

                if (oddsType === 'decimal') {
                    //
                } else if (oddsType === 'fractional') {
                    decimal = calculate.fractionalReverse(value);
                } else if (oddsType === 'american') {
                    decimal = calculate.americanReverse(value);
                } else if (oddsType === 'implied') {
                    decimal = calculate.impliedReverse(value);
                } else if (oddsType === 'hong-kong') {
                    decimal = calculate.hongKongReverse(value);
                } else if (oddsType === 'indonesian') {
                    decimal = calculate.indonesianReverse(value);
                } else if (oddsType === 'malaysian') {
                    decimal = calculate.malaysianReverse(parseFloat(value));
                }

                $decimal.val(decimal);

                if (oddsType !== 'hong-kong') {
                    $hongKong.val(calculate.hongKong(decimal));
                }
                if (oddsType !== 'fractional') {
                    $fractional.val(calculate.indonesian(decimal));
                }
                if (oddsType !== 'implied') {
                    $implied.val(calculate.implied(decimal));
                }
                if (oddsType !== 'american') {
                    $american.val(calculate.american(decimal));
                }
                if (oddsType !== 'indonesian') {
                    $indonesian.val(calculate.indonesian(decimal));
                }
                if (oddsType !== 'malaysian') {
                    $malaysian.val(calculate.malaysian(decimal));
                }
            });

        });
    };

}(jQuery));
// let progressBarAbsolute = document.getElementById("progress-bar-absolute");
// let progressBarPercentage = document.getElementById("progress-bar-percentage");
// let progressBarSeconds = document.getElementById("progress-bar-seconds");
let maxLines;
let linesLeft;
let timeStart = performance.now();

var mainWorker = cw({
    runSimulation: function (
        {
            linesCount,
            startingBalance,
            bets,
            strategy,
            baseStakeSize,
            hideGraph,
            proportionalBetPercentage,
            odds,
            probability
        }
    ) {
        let stats = {
            balanceAvg: 0,
            balanceGeoMean: 0,
            finalBalanceSum: 0,
            stakesSum: 0,
            biggestBalance: 0,
            finalBalances: [],

            balanceZero: 0,
            balanceLess1: 0,
            balanceLess50: 0,
            balanceLess100: 0,
            balanceMore100: 0,
            balanceMore200: 0,
            balanceMore300: 0,
            balanceMore500: 0,
            balanceMore1000: 0,
        };

        let lines = [];

        for (let j = 0; j < linesCount; j++) {
            this.fire('moveProgressBar');
            let lineData = [];
            let balance = startingBalance;
            let stakeSize;

            for (let i = 0; i < bets; i++) {
                let outcome = (Math.random() < probability / 100) ? (odds - 1) : -1;
                let firstBet = i === 0;
                let lastBet = (i + 1) === bets;
                let betWon = outcome >= 0;
                let betLost = outcome < 0;
                let minStakeReached = false;
                // let stakeSize;

                if (firstBet) {
                    if (strategy === 'proportional') {
                        stakeSize = proportionalBetPercentage * balance / 100;
                    } else {
                        stakeSize = baseStakeSize;
                    }
                }

                if (stakeSize > balance) {
                    stakeSize = balance;
                }

                let currentBetStake = stakeSize;

                outcome = outcome * stakeSize;
                balance += outcome;

                if (balance <= 0) {
                    balance = 0;
                    lastBet = true;
                    if (!hideGraph) {
                        lineData.push(0);
                    }
                } else {
                    stats.stakesSum += stakeSize;
                    if (!hideGraph) {
                        lineData.push(Math.round(balance * 100) / 100);
                    }
                }

                if (strategy === 'fixed') {
                    stakeSize = baseStakeSize;
                } else if (strategy === 'martingale') {

                    if (betWon) {
                        stakeSize = baseStakeSize;
                    } else if (betLost) {
                        stakeSize *= 2;
                    }
                } else if (strategy === 'alembert') {
                    if (betWon) {
                        stakeSize -= baseStakeSize;
                        if (stakeSize <= 0) {
                            minStakeReached = true;
                            stakeSize = baseStakeSize;
                        }
                    } else if (betLost) {
                        stakeSize += baseStakeSize;
                    }
                } else if (strategy === 'proportional') {
                    stakeSize = proportionalBetPercentage * balance / 100;
                    if (balance < 1) {
                        if (!hideGraph) {
                            lineData.push(Math.round(balance * 100) / 100);
                        }
                        lastBet = true;
                    }
                } else {
                    alert('unknown strategy');
                }

                if (lastBet) {
                    stats.finalBalanceSum += balance;
                    stats.finalBalances.push(balance);
                    if (balance > stats.biggestBalance) {
                        stats.biggestBalance = balance;
                    }

                    if (balance === 0) {
                        stats.balanceZero++;
                    }
                    if (balance < 1) {
                        stats.balanceLess1++;
                    }
                    if (balance < 50) {
                        stats.balanceLess50++;
                    }
                    if (balance < 100) {
                        stats.balanceLess100++;
                    }
                    if (balance > 100) {
                        stats.balanceMore100++;
                    }
                    if (balance > 200) {
                        stats.balanceMore200++;
                    }
                    if (balance > 300) {
                        stats.balanceMore300++;
                    }
                    if (balance > 500) {
                        stats.balanceMore500++;
                    }
                    if (balance > 1000) {
                        stats.balanceMore1000++;
                    }
                    break;
                }
            }

            if (!hideGraph) {
                lines.push({
                    data: lineData
                });
            }
        }

        // TODO: move
        function median(values) {
            if (values.length === 0) return 0;

            values.sort(function (a, b) {
                return a - b;
            });

            var half = Math.floor(values.length / 2);

            if (values.length % 2)
                return values[half];

            return (values[half - 1] + values[half]) / 2.0;
        }


        /**
         * FUNCTION: gmean( arr[, accessor ] )
         *    Computes the geometric mean of an array.
         *
         * @param {Array} arr - input array
         * @param {Function} [accessor] - accessor function for accessing numeric array values
         * @returns {Number|null} geometric mean
         */
        function gmean(arr, clbk) {
            if (!Array.isArray(arr)) {
                throw new TypeError('gmean()::invalid input argument. Must provide an array. Value: `' + arr + '`.');
            }
            if (arguments.length > 1 && !isFunction(clbk)) {
                throw new TypeError('gmean()::invalid input argument. Accessor must be a function. Value: `' + clbk + '`.');
            }
            var len = arr.length,
                sum = 0,
                val,
                i;

            if (!len) {
                return null;
            }
            if (clbk) {
                for (i = 0; i < len; i++) {
                    val = clbk(arr[i]);
                    if (val <= 0) {
                        return NaN;
                    }
                    sum += Math.log(val) / len;
                }
            } else {
                for (i = 0; i < len; i++) {
                    val = arr[i];
                    if (val <= 0) {
                        return NaN;
                    }
                    sum += Math.log(val) / len;
                }
            }
            return Math.exp(sum);
        } // end FUNCTION gmean()

        stats.balanceAvg = Math.round(stats.finalBalanceSum / linesCount * 100) / 100;
        stats.balanceGeoMean = Math.round(gmean(stats.finalBalances) * 100) / 100;
        stats.balanceMedian = Math.round(median(stats.finalBalances) * 100) / 100;

        return {
            stats: stats,
            lines: lines
        };
    }
}).on('moveProgressBar', function () {
    // let timeEnd = performance.now();
    //
    // linesLeft -= 1;
    // progressBarAbsolute.innerText = linesLeft;
    // progressBarPercentage.innerText = 100 - Math.round(linesLeft * 100 / maxLines) + '%';
    // progressBarSeconds.innerText = Math.round(((timeEnd - timeStart) / 1000)) + 's ';
});

jQuery(function ($) {
    $(document).ready(function () {

        mainFunction();

        maxLines = parseInt($('#number-of-trials').val()) * $('.j-tool').length;
        linesLeft = maxLines;

        function mainFunction() {

            let odds = parseFloat($('#odds').val());
            let probability = parseFloat($('#probability').val());
            let bets = parseInt($('#bets-per-trial').val());
            let linesCount = parseInt($('#number-of-trials').val());
            let startingBalance = parseFloat($('#bankroll').val());
            let baseStakeSize = parseFloat($('#base-stake-size').val());
            let hideGraph = $('#hide-graph').is(':checked');

            $('.j-tool').each(function () {
                let strategy = $(this).data('strategy');
                let graphId = $(this).data('graph-id');
                let proportionalBetPercentage = parseFloat($('#' + graphId + '-percentage').val());
                let toolWrap = $(this);

                mainWorker.runSimulation({
                    linesCount,
                    startingBalance,
                    bets,
                    strategy,
                    baseStakeSize,
                    hideGraph,
                    proportionalBetPercentage,
                    odds,
                    probability
                }).then(function (x) {
                    let stats = x.stats;
                    let lines = x.lines;
                    let statsWrap = $('#stats-' + graphId);
                    $('.avg-ending-bankroll', statsWrap).html(removeDecimals(stats.balanceAvg));
                    $('.balance-geo-mean', statsWrap).html(removeDecimals(stats.balanceGeoMean));

                    $('.balance-zero', statsWrap).html(removeDecimals(stats.balanceZero / linesCount * 100));
                    $('.balance-less-1', statsWrap).html(removeDecimals(stats.balanceLess1 / linesCount * 100));
                    $('.balance-less-50', statsWrap).html(removeDecimals(stats.balanceLess50 / linesCount * 100));
                    $('.balance-less-100', statsWrap).html(removeDecimals(stats.balanceLess100 / linesCount * 100));
                    $('.balance-more-100', statsWrap).html(removeDecimals(stats.balanceMore100 / linesCount * 100));
                    $('.balance-more-200', statsWrap).html(removeDecimals(stats.balanceMore200 / linesCount * 100));
                    $('.balance-more-300', statsWrap).html(removeDecimals(stats.balanceMore300 / linesCount * 100));
                    $('.balance-more-500', statsWrap).html(removeDecimals(stats.balanceMore500 / linesCount * 100));
                    $('.balance-more-1000', statsWrap).html(removeDecimals(stats.balanceMore1000 / linesCount * 100));

                    $('.balance-median', statsWrap).html(removeDecimals(stats.balanceMedian));
                    $('.stakes-sum', statsWrap).html(removeDecimals(stats.stakesSum));
                    $('.biggest-balance', statsWrap).html(removeDecimals(stats.biggestBalance));

                    if (hideGraph) {
                        $(this).hide();
                    } else {
                        // console.log($(this)[0]);
                        let graphOptions = {
                            chart: {
                                renderTo: toolWrap[0],
                                type: 'line'
                            },
                            series: lines,
                            xAxis: {
                                max: bets,
                            },
                            legend: {
                                enabled: false
                            },
                            title: {
                                text: ''
                            },
                        };

                        new Highcharts.Chart(graphOptions);
                    }
                });
            });
        }
    });
});

function removeDecimals(val) {
    return Math.round(val * 100);
}


/**
 * FUNCTION: gmean( arr[, accessor ] )
 *    Computes the geometric mean of an array.
 *
 * @param {Array} arr - input array
 * @param {Function} [accessor] - accessor function for accessing numeric array values
 * @returns {Number|null} geometric mean
 */
function gmean(arr, clbk) {
    if (!Array.isArray(arr)) {
        throw new TypeError('gmean()::invalid input argument. Must provide an array. Value: `' + arr + '`.');
    }
    if (arguments.length > 1 && !isFunction(clbk)) {
        throw new TypeError('gmean()::invalid input argument. Accessor must be a function. Value: `' + clbk + '`.');
    }
    var len = arr.length,
        sum = 0,
        val,
        i;

    if (!len) {
        return null;
    }
    if (clbk) {
        for (i = 0; i < len; i++) {
            val = clbk(arr[i]);
            if (val <= 0) {
                return NaN;
            }
            sum += Math.log(val) / len;
        }
    } else {
        for (i = 0; i < len; i++) {
            val = arr[i];
            if (val <= 0) {
                return NaN;
            }
            sum += Math.log(val) / len;
        }
    }
    return Math.exp(sum);
} // end FUNCTION gmean()
let godsOfOddsGlobals = {
    defaultGraphStyling: {
        color: '#2d3a6e',
        type: "area",
        fillColor: {
            linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
                [0, 'rgba(45, 58, 110,1)'],
                [1, 'rgba(45, 58, 110,0)']
            ]
        },
        negativeColor: 'rgb(255, 77, 77)',
        negativeFillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(255, 102, 0, 0)'],
                [1, 'rgba(255, 102, 0, 1)']
            ]
        }
    }
};


Highcharts.setOptions({
    credits: false,
    chart: {
        animation: false
    },
    exporting: {
        enabled: false
    },
    rangeSelector: {
        x: 0,
        y: 40
    }
});


jQuery(document).ready(function ($) {
    initTipsterGraph();
    initTipLink();
    initXSRFToken();
    initCountdown();
    $('.j-odds-converter').oddsConverter();

    function initTipsterGraph() {
        $('.j-tipster').each(function () {
            $graph = $('.j-tipster-graph', this);
            var data = $(this).data('graph');

            $graph.highcharts('StockChart', {
                chart: {
                    backgroundColor: '#f7f7f7',
                    margin: [0, 0, 0, 0]
                },
                xAxis: {
                    showLastLabel: true,
                    endOnTick: false,
                    labels: {
                        enabled: false,
                        style: {
                            color: '#000'
                        }
                    },
                    tickInterval: 1,
                    gridLineWidth: 1,
                    minorTickLength: 0,
                    tickLength: 0
                },
                yAxis: [
                    {
                        lineColor: '',
                        plotLines: [{
                            value: 0,
                            width: 1,
                        }],
                        tickWidth: 1,
                        labels: {
                            enabled: false
                        },
                        tickInterval: 1,
                        gridLineWidth: 1,
                        minorTickLength: 0,
                        tickLength: 0,
                    },
                    {
                        linkedTo: 0,
                        opposite: false,
                        reserveSpace: true,
                        title: {
                            text: '',
                        },
                        labels: {
                            enabled: false
                        }
                    },
                ],
                rangeSelector: {
                    enabled: false
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        enableMouseTracking: false
                    }
                },
                series: [
                    Object.assign(
                        {
                            name: '',
                            data: data,
                            marker: {
                                enabled: false
                            },

                        }, godsOfOddsGlobals.defaultGraphStyling)
                ],
            });
        });
    }

    function initTipLink() {
        $('.j-tip-row').not('a').on('click', function (e) {
            if (!$(e.target).is('a') && !$(e.target).is('img')) {
                location.href = $(this).data('url');
                return false;
            }
        });
    }

    function initXSRFToken() {
        $.ajaxSetup({
            headers: {'X-XSRF-TOKEN': $('meta[name=_token]').attr('content')}
        });
    }

    function initCountdown() {
        if ($('.j-countdown').length > 0) {
            setInterval(function () {
                $('.j-countdown').not('.j-stopped').each(function () {
                    var string = $(this).html();
                    var timeVal = string.split(' : ');

                    if (string === '00 : 00 : 00') {
                        $(this).addClass('j-stopped');
                        $(this).html('Started');
                        return;
                    }

                    --timeVal[2];

                    if ((timeVal[2]) < 0) {
                        --timeVal[1];
                        timeVal[2] = 59;
                    }

                    timeVal[2] = zeroPad(timeVal[2], 2);

                    if (timeVal[1] < 0) {
                        --timeVal[0];
                        timeVal[1] = 59;
                    }

                    timeVal[1] = zeroPad(timeVal[1], 2);

                    if (timeVal[0] <= 0) {
                        timeVal[0] = 0;
                    }

                    timeVal[0] = zeroPad(timeVal[0], 2);


                    $(this).html(timeVal.join(' : '));
                });
            }, 1000);
        }
    }
});


function zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}
/**
 * source: https://www.math.ucla.edu/~tom/distributions/normal.html
 */
function normalcdf(X) {   //HASTINGS.  MAX ERROR = .000001
    var T = 1 / (1 + .2316419 * Math.abs(X));
    var D = .3989423 * Math.exp(-X * X / 2);
    var Prob = D * T * (.3193815 + T * (-.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
    if (X > 0) {
        Prob = 1 - Prob
    }
    return Prob
}


function normalDistribution(x, mean, standardDeviation) {
    with (Math) {
        if (standardDeviation < 0) {
            alert("The standard deviation must be non negative.")
        } else if (standardDeviation == 0) {
            if (x < mean) {
                Prob = 0
            } else {
                Prob = 1
            }
        } else {
            Prob = normalcdf((x - mean) / standardDeviation);
            Prob = round(100000 * Prob) / 100000;
        }
    }
    return Prob;
}